import { Stripe } from '@stripe/stripe-js';
import { AppState } from '~/framework/appState';
import { CompanyIdProvider } from '~/gateways/network/companyIdProvider';
import { CompanyInfo } from '~/types/CompanyInfo';
import {
  RentalApplicationErrorCode,
  RentalHistoryErrorCode,
  RentalApplication,
  EmploymentHistoryErrorCode,
  TransUnionScreening,
} from '~/types/RentalApplication';
import { Unit, PaginUnit, UnitParams, CompanyUnit, TenantHydratedUnitListingDto } from '~/types/Unit';
import { Annotation } from '~/types/annotation';
import { SignablePdf } from '~/types/signablePdf';

export interface PlaidInfo {
  linkToken?: string | null;
  publicToken?: string | null;
}

export interface StripeInfo {
  intentId: string;
  account?: Stripe;
  clientSecret?: string;
  sessionId?: string;
  stripeAccountId: string;
  totalAmount: number;
  isCheckoutFulfilled?: boolean;
}

export interface RentalApplicationCredentials {
  id: string;
  password: string;
}

export interface RentalApplicationInfo {
  application?: RentalApplication;
  credentials?: RentalApplicationCredentials;
  transUnionScreening?: TransUnionScreening;
  isPaid?: boolean;
  errors: RentalApplicationErrorCode[];
  rentalHistoryErrors: RentalHistoryErrorCode[][];
  employmentHistoryErrors: EmploymentHistoryErrorCode[][];
  stripe?: StripeInfo;
}

export enum PaymentErrorCode {
  ACCOUNT_ERROR,
  PAYMENT_TYPE_ERROR,
  PAYMENT_FREQUENCY_ERROR,
  MAXIMUM_AMOUNT_ERROR,
  FIXED_AMOUNT_ERROR,
  START_DATE_ERROR,
  END_DATE_ERROR,
}

export interface SignerState {
  document: SignablePdf;
  signedAnnotations: Set<string>;
  nextAnnotation?: Annotation;
}

export interface User {
  companyInfo?: CompanyInfo;
  unitsListing?: Unit[];
  rentalApplication: RentalApplicationInfo;
  signerState: SignerState;
  unitParams?: UnitParams;
  pinnedUnits?: PaginUnit;
  companyUnitsListing?: PaginUnit;
  prevCompanyUnitsListingItems?: CompanyUnit[];
  companyUnitListingDetail?: TenantHydratedUnitListingDto;
}

export class MainAppState extends AppState<User> implements CompanyIdProvider {
  user: User = {
    companyUnitListingDetail: undefined,
    prevCompanyUnitsListingItems: undefined,
    unitParams: undefined,
    pinnedUnits: undefined,
    companyUnitsListing: undefined,
    unitsListing: undefined,
    rentalApplication: {
      application: undefined,
      credentials: undefined,
      transUnionScreening: undefined,
      isPaid: false,
      errors: [],
      rentalHistoryErrors: [],
      employmentHistoryErrors: [],
      stripe: undefined,
    },
    signerState: {
      document: {
        id: '',
        leaseName: '',
        pages: [],
        signerInitials: '',
        signerName: '',
      },
      signedAnnotations: new Set<string>(),
    },
  };

  public getState(): User {
    return { ...this.user };
  }

  public getCompanyId = (): string | undefined => {
    return this.user.companyInfo?.id;
  };
}

export const mainAppState = new MainAppState();
