import { CompanyInfo, CompanyStyle } from '~/types/CompanyInfo';
import { Gateway } from './gateway';
import { BaseNetworkManager } from './network/baseNetworkManager';
import { MagicRequest, RequestMethod } from './network/magicRequest';

const ACCOUNTS_URL = '/api/companies/url';

class CompanyGateway extends Gateway {
  public getCompanyInfo = async (domain: string): Promise<CompanyInfo> => {
    const request: MagicRequest = {
      url: ACCOUNTS_URL + `?domain=${domain}`,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return this.createCompanyInfoFromJson(json);
  };

  private createCompanyInfoFromJson(json: any): CompanyInfo {
    return {
      id: json.id,
      name: json.name,
      email: json.email,
      url: json.subUrl,
      language: json.language,
      phoneNumber: json.phoneNumber,
      timeZone: json.timeZoneInfo,
      style: this.createCompanyStyleFromJson(json.theme),
      logo: json.settings?.logoUrl,
      coverUrl: json.settings?.coverUrl,
      customDomain: json.customDomain,
      facebook: json?.settings?.socialLinks?.facebook,
      instagram: json?.settings?.socialLinks?.instagram,
      linkedIn: json?.settings?.socialLinks?.linkedIn,
      twitter: json?.settings?.socialLinks?.twitter,
      metadataTitle: json?.settings?.metadata?.title,
      metadataDescription: json?.settings?.metadata?.description,
      metadataThemeColor: json?.settings?.metadata?.themeColor,
      metadataTitleTemplate: json?.settings?.metadata?.titleTemplate,
    };
  }

  private createCompanyStyleFromJson(theme: string): CompanyStyle {
    switch (theme) {
      case 'Purple':
        return {
          primaryColor: '#A126EC',
          hoverColor: '#2B7FFF',
          activeColor: '#7600BE',
          gradientStartColor: '#510FA6',
          gradientEndColor: '#000000',
        };
      case 'Orange':
        return {
          primaryColor: '#DF7818',
          hoverColor: '#EB8628',
          activeColor: '#DE4D16',
          gradientStartColor: '#E9862D',
          gradientEndColor: '#E2602F',
        };
      case 'Green':
        return {
          primaryColor: '#02946E',
          hoverColor: '#12B88D',
          activeColor: '#006D51',
          gradientStartColor: '#05A279',
          gradientEndColor: '#0DB88C',
        };
      default: //Blue
        return {
          primaryColor: '#0C48BC',
          hoverColor: '#2B7FFF',
          activeColor: '#0054D4',
          gradientStartColor: '#0C48BC',
          gradientEndColor: '#0065FF',
        };
    }
  }

  public getLanguages = async (): Promise<{ name: string; code: string }[]> => {
    const request: MagicRequest = {
      url: '/api/public/languages',
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    return await response.json();
  };
}

export const companyGateway = new CompanyGateway(new BaseNetworkManager());
